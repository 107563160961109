// noinspection JSUnresolvedVariable,JSUnresolvedFunction
const domainUrl = string => `${process.env.NODE_ENV === "development" ? "http" : "https"}://${string}`;
const {Get_All_Posts} = require("./PostControllers");
const {Get_Form_By_Id} = require("./FormControllers");
const {Get_Product_For_Product_Inner_Page, Get_New_Category_Products} = require("./ProductController");

////////////////////////////////////
// Constants

const sxForMapKeys = ["class", "backgroundColor", "backgroundImage", "height", "alignItems", "alignItemsRow", "fullScreen", "children", "parallax", "heightIsActive"]

////////////////////////////////////
function Save_Builder_data(body, models) {
    return new Promise(async (resolve, reject) => {
        const existingBuilder = await models
            .page_builder
            .findOne({
                page_id: +body.page_id,
                selectedLocale: body.selectedLocale
            });

        if (existingBuilder) {
            // README!!! A document with the same page_id and selectedLocale already exists, so update it.
            existingBuilder.tree = body.tree;
            existingBuilder.components = body.children;
            existingBuilder.initialLayout = body.initialLayout;
            existingBuilder.componentsBuilder = body.componentsBuilder;
            existingBuilder.save()
                .then(() => resolve("The item was updated successfully"))
                .catch(err => {
                    console.log(err, "err")
                    reject(err)
                });
        } else {
            //README!!! No document with the same page_id and selectedLocale exists, so create a new one.
            const newBuilder = new models
                .page_builder({
                    page_id: body.page_id,
                    selectedLocale: body.selectedLocale,
                    tree: body.tree,
                    components: body.children,
                    initialLayout: body.initialLayout,
                    componentsBuilder: body.componentsBuilder
                });
            newBuilder.save()
                .then(() => resolve("The item was saved successfully"))
                .catch(err => {
                    console.log(err, "err 2")
                    reject(err)
                });
        }
    })

}

const renderMapData = (initialLayout, forMap = "class") => {
    if (!initialLayout || !initialLayout.length) return null;
    return initialLayout?.map((item) => {
        return Object.keys(item).map((element) => {
            return item[+element].map((child) => {
                if (forMap === "class") return child.children.map(el => el.class)
                return child[forMap]
            })
        })
    })
}

const builderRowStylesHandler = (
    {
        index,
        tab,
        dbName,
        renderRowBackground,
        renderRowAlignItems,
        renderRowBgImage,
        renderRowHeight,
        renderRowHeightIsActive,
        renderParallax,
    }
) => {
    if (renderRowBackground?.[0]?.[tab - 1]?.[index]
        && renderRowBackground?.[0]?.[tab - 1]?.[index] !== "transparent"
        && renderRowBackground?.[0]?.[tab - 1]?.[index] !== "none"
    ) {
        return {
            background: renderRowBackground?.[0]?.[tab - 1]?.[index],
            backgroundAttachment: renderParallax?.[0]?.[tab - 1]?.[index] ? "fixed" : "unset",
            display: "flex",
            alignItems: renderRowAlignItems?.[0]?.[tab - 1]?.[index] ?? "flex-start",
            height: renderRowHeightIsActive?.[0]?.[tab - 1]?.[index] ? renderRowHeight?.[0]?.[tab - 1]?.[index] + "px" : "unset",
        }
    } else if (renderRowBgImage?.[0]?.[tab - 1]?.[index] && renderRowBgImage?.[0]?.[tab - 1]?.[index] !== "none") {
        return {
            backgroundImage: `url(${domainUrl(`${dbName}/${renderRowBgImage?.[0]?.[tab - 1]?.[index]}`)})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundAttachment: renderParallax?.[0]?.[tab - 1]?.[index] ? "fixed" : "unset",
            height: renderRowHeightIsActive?.[0]?.[tab - 1]?.[index] ? renderRowHeight?.[0]?.[tab - 1]?.[index] + "px" : "unset",
            display: "flex",
            alignItems: renderRowAlignItems?.[0]?.[tab - 1]?.[index] ?? "flex-start"
        }
    }
    return {
        background: "transparent",
        display: "flex",
        backgroundAttachment: renderParallax?.[0]?.[tab - 1]?.[index] ? "fixed" : "unset",
        alignItems: renderRowAlignItems?.[0]?.[tab - 1]?.[index] ?? "flex-start",
        height: renderRowHeightIsActive?.[0]?.[tab - 1]?.[index] ? renderRowHeight?.[0]?.[tab - 1]?.[index] + "px" : "unset",
    }
};

const builderColumnStylesHandler = ({dbName, renderColumn}) => {
    const {
        backgroundType = "",
        backgroundColor = "",
        backgroundImage = "",
        alignItems = "",
        height = "",
        heightIsActive = false
    } = renderColumn || {}

    let background = {
        // display: "flex",
        alignItems,
        height: heightIsActive ? height + "px" : "unset",
    }

    if (backgroundType === "Color") {
        background = {
            ...background,
            backgroundColor
        }
    } else if (backgroundType === "Image") {
        background = {
            ...background,
            backgroundImage: `url(${domainUrl(`${dbName}/${backgroundImage}`)})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        }
    } else {
        background = {
            ...background,
            background: "none"
        }
    }

    return background
}

const createBuilderData = (array, arrayElement) => {
    let data = array?.map((el) => {
        if (Object.keys(el)[0] === arrayElement) {
            return Object.values(el)[0]
        }
    })
    data = data?.filter(element => element !== undefined);
    return data[0]
}

function convertStyles(settings, cursor) {
    let top = 0;
    let right = 0;
    let bottom = 0;
    let left = 0;
    let paddTop = 0;
    let paddRight = 0;
    let paddBottom = 0;
    let paddLeft = 0;
    let fontFamily = "unset";
    let image = [];
    let color = "unset";
    let width = "unset";
    let height = "initial";
    let align = "";
    let richEditorValue = "";
    let accordionTitle = "";
    let accordionDescription = "";
    let borderTopLeftRadius = "0";
    let borderTopRightRadius = "0";
    let borderBottomLeftRadius = "0";
    let borderBottomRightRadius = "0";
    let position = "";
    let zIndex = "";
    let positionLeft = "";
    let positionTop = "";
    let backgroundColor = "unset";
    let animationAosInit = '';
    let display = '';
    let justifyContent = '';


    settings?.map((item) => {
        Object.keys(item)?.forEach((key) => {
            if (key !== undefined) {
                switch (key) {
                    case 'data-aos':
                        return (animationAosInit = item['data-aos']);
                    case "marginTop":
                        return (top = item.marginTop);
                    case "marginRight":
                        return (right = item.marginRight);
                    case "marginBottom":
                        return (bottom = item.marginBottom);
                    case "marginLeft":
                        return (left = item.marginLeft);
                    case "paddingTop":
                        return (paddTop = item.paddingTop);
                    case "paddingRight":
                        return (paddRight = item.paddingRight);
                    case "paddingBottom":
                        return (paddBottom = item.paddingBottom);
                    case "paddingLeft":
                        return (paddLeft = item.paddingLeft);
                    case "font-family":
                        return (fontFamily = item["font-family"]);
                    case "color":
                        return (color = item.color);
                    case "width":
                        return (width = item.width);
                    case "height":
                        return (height = item.height);
                    case "text-align":
                        return (align = item["text-align"]);
                    case "border-top-left-radius":
                        return (borderTopLeftRadius = item["border-top-left-radius"]);
                    case "border-top-right-radius":
                        return (borderTopRightRadius = item["border-top-right-radius"]);
                    case "border-bottom-left-radius":
                        return (borderBottomLeftRadius = item["border-bottom-left-radius"]);
                    case "border-bottom-right-radius":
                        return (borderBottomRightRadius = item["border-bottom-right-radius"]);
                    case "position":
                        return (position = item.position);
                    case "z-index":
                        return (zIndex = item["z-index"]);
                    case "left":
                        return (positionLeft = item.left);
                    case "top":
                        return (positionTop = item.top);
                    case "background-color":
                        return (backgroundColor = item["background-color"])
                    case 'display':
                        return (display =
                            item['display'])
                    case 'justify-content':
                        return (justifyContent =
                            item['justify-content'])
                    default:
                        return false;
                }
            }
        });
    });
    const parentStyles = {
        display: display,
        justifyContent: `${justifyContent}`,
        textAlign: `${align}`,
    };

    let animationAos = {
        ['data-aos']: animationAosInit,
    }
    const extractNumberAndUnitFromString = (str) => {
        if (str) {
            const match = str.match(/(\d+)([a-zA-Z%]*)/);
            return match ? {number: parseInt(match[1], 10) || "", resizeVal: match[2]} : {};
        }
        return {number: str, resizeVal: 'px'}
    };
    const {number: widthNumber = "unset", resizeVal: widthResizeVal} = extractNumberAndUnitFromString(width) || {}
    const {number: heightNumber = "unset", resizeVal: heightResizeVal} = extractNumberAndUnitFromString(height) || {}

    const handleWidthHeightValue = (number, value ) => {
        if (number === "unset") {
            return "unset";
        } else {
            if (!!number) {
                return number + (value || "px")
            }
        }
    }

    const styles = {
        margin: `${top}px ${right}px ${bottom}px ${left}px`,
        padding: `${paddTop}px ${paddRight}px ${paddBottom}px ${paddLeft}px`,
        fontFamily: `${fontFamily}`,
        color: color,
        backgroundColor: backgroundColor,
        width: handleWidthHeightValue(widthNumber, widthResizeVal),
        height: handleWidthHeightValue(heightNumber, heightResizeVal),
        borderTopLeftRadius: `${borderTopLeftRadius}px`,
        borderTopRightRadius: `${borderTopRightRadius}px`,
        borderBottomLeftRadius: `${borderBottomLeftRadius}px`,
        borderBottomRightRadius: `${borderBottomRightRadius}px`,
        position: position,
        zIndex: `${zIndex}`,
        left: `${positionLeft}px`,
        top: `${positionTop}px`,
        ...cursor
    };
    return {styles, parentStyles, animationAos}
}

const createRightDate = (date) => {
    let day = new Date(date);

    let dd = String(day.getDate()).padStart(2, "0");
    let mm = String(day.getMonth() + 1).padStart(2, "0");
    let yyyy = day.getFullYear();
    return dd + "/" + mm + "/" + yyyy
}

const Get_Data = async (pageId, locale, models, dbName, domain, selectedRate) => {
    const res = await models.page_builder.aggregate([{$match: {page_id: +pageId, selectedLocale: locale}}])
    console.log(res, "res res res")
    const deepClone = item => JSON.parse(JSON.stringify(item));
    const tree = deepClone(res)?.[0]?.tree;
    const componentsData = deepClone(res)?.[0]?.components
    const initialLayout = sxForMapKeys.map(sxItem => renderMapData(deepClone(res)?.[0]?.initialLayout, sxItem))
    const handleImageDataShow = (builderData, tab) => {
        if (builderData?.content === "ImageUpload") {
            const {settings, isLinkChecked, linkValue: existsLinkVal = null,} = builderData || {};
            const findFSItem = settings.find(item => item.fullScreen?.fullScreen)?.fullScreen
            return {
                imageStyles: !(!!existsLinkVal) && findFSItem?.tabId === tab ? {
                    width: "150%",
                    marginLeft: "-25%"
                } : {},
                defaultImage: builderData.images[0] && builderData.images[0].imageUpload
                    ? domainUrl(`${dbName}/${builderData.images[0]?.imageUpload}`)
                    : domainUrl(`${dbName}/admin-themes/wt-admin-theme-one/assets/images/placeholder-icon.svg`),
                hoverImage: builderData.images[0] && builderData.images[0].hoverImage
                    ? domainUrl(`${dbName}/${builderData.images[0]?.hoverImage}`)
                    : '',
                linkTarget: !!isLinkChecked ? "_blank" : "_self",
            }
        }
        return {}
    }
    const handleSliderDataShow = (builderData) => {
        if (builderData?.content === "Slider") {
            const {slider: {slides, settings}} = builderData || {};
            return {
                slider: {
                    slides: slides.map(({slider_path, content, ...elem}) => {
                        return {
                            ...elem,
                            slider_path: slider_path !== ""
                                ? domainUrl(`${dbName}/${slider_path}`)
                                : domainUrl(`${dbName}/admin-themes/wt-admin-theme-one/assets/images/placeholder-icon.svg`),
                            content: {
                                ...content,
                                showDescription: content.description ? "Desc" : "Empty",
                                showButtonText: content.buttonText ? "BtnText" : "Empty",
                                buttonLinkTarget: content.link_target ? "_blank" : ""
                            }
                        }
                    }),
                    settings
                },
            }
        }
        return {}
    }
    const handleCarouselDataShow = (builderData) => {
        if (
            builderData?.content === "ImageBox" ||
            builderData?.content === "Testimonials" ||
            builderData?.content === "BrandList"
        ) {
            const {carousel: {cards, settings}} = builderData || {};
            return {
                carousel: {
                    cards: cards.map(({image_path, linkValue, isLinkChecked, ...elem}) => {
                        return {
                            ...elem,
                            linkValue: linkValue ?? "/",
                            targeting: isLinkChecked ? "_blank" : "",
                            image_path: image_path !== ""
                                ? domainUrl(`${dbName}/${image_path}`)
                                : domainUrl(`${dbName}/admin-themes/wt-admin-theme-one/assets/images/placeholder-icon.svg`),
                        }
                    }),
                    settings
                },
            }
        }
        return {}
    }

    const handleMasonryDataShow = (builderData) => {
        if (builderData?.content === "Masonry" ) {
            const {masonry: {images, settings}} = builderData || {};
            return {
                masonry: {
                    images: images.map(({image_path, ...elem}) => {
                        return {
                            ...elem,
                            image_path: image_path !== ""
                                ? domainUrl(`${dbName}/${image_path}`)
                                : domainUrl(`${dbName}/admin-themes/wt-admin-theme-one/assets/images/placeholder-icon.svg`),
                        }
                    }),
                    settings
                },
            }
        }
        return {}
    }

    async function handleButtonDataShow(builderData) {
        if (builderData?.content === "Button") {
            if (builderData.buttonType === "Buy now") {
                const {
                    product_flat: [{
                        url_key: slug = ""
                    }] = [{}]
                } = builderData.productForBuyNow

                const options = {
                    locale,
                    selectedRate
                }
                const [product] = await Get_Product_For_Product_Inner_Page(slug, options, models) || {}

                return {
                    linkValue: builderData.linkValue ?? "/",
                    targeting: builderData.isLinkChecked ? "_blank" : "",
                    productForBuyNow: product
                }
            }

            return {
                linkValue: builderData.linkValue ?? "/",
                targeting: builderData.isLinkChecked ? "_blank" : "",
            }
        }
        return {}
    }

    async function handleBlogDataShow(builderData) {
        if (builderData?.content === "Posts") {
            const {data} = await Get_All_Posts(
                {
                    domain,
                    dbName,
                    locale,
                    page: 1,
                    limit: builderData.blog?.quantity,
                    filterCategory: builderData.blog?.category?.slug
                }, models
            ) || {}
            return {
                postComponentInfo: data.map(({published_at, created_at, updated_at, ...post}) => {
                    let rightDay = null;
                    if (!!published_at) {
                        rightDay = createRightDate(published_at)
                    } else {
                        if (!!updated_at) {
                            rightDay = createRightDate(updated_at)
                        } else {
                            rightDay = createRightDate(created_at)
                        }
                    }
                    return {
                        ...post,
                        rightDay,
                    }
                }),
                showPostListCls: !!data.length ? "" : "empty_post_list"
            }
        }
        return {}
    }

    async function handleProductDataShow(builderData) {
        if (builderData?.content === "ProductList") {
            const {
                productList: {
                    category: {
                        slug = ""
                    } = {},
                    quantity = 4,
                    block_title = ""
                } = {}
            } = builderData || {}

            const {data} = await Get_New_Category_Products(
                slug,
                locale,
                quantity,
                models
            ) || {}

            return {
                productListComponentInfo: {
                    products: data,
                    block_title
                },
                showProductListCls: !!data.length ? "" : "empty_post_list"
            }
        }
        return {}
    }

    async function handleFormDataShow(builderData) {
        if (builderData?.content === "FormBuilder") {
            const data = await Get_Form_By_Id(
                {
                    form_id: builderData.form.id,
                    locale,
                }, models
            ).then(res => {
                return JSON.parse(JSON.stringify(res.data))
            })

            const updatedData = {}
            updatedData.data = {
                ...data,
                form_builder_json: data.form_builder_json.map((item) => {
                    return {
                        ...item,
                        descriptionShow: !!item.description
                    }
                })
            }

            return {
                formComponentInfo: updatedData
            }
        }
        return {}
    }

    //TODO !!! if we will have promise error move it in map function without async function
    const showOneComponentToggle = async (builderData, tab) => {
        let cursor = {}
        if (builderData?.content === "ImageUpload") {
            cursor = {
                cursor: builderData.linkValue ?
                    "pointer" :
                    builderData.switchValue ?
                        "zoom-in" :
                        "default"
            }
        }

        return {
            ...builderData,
            convertedSettings: convertStyles(builderData?.settings, cursor),
            ...handleImageDataShow(builderData, tab),
            ...handleSliderDataShow(builderData),
            ...handleCarouselDataShow(builderData),
            ...handleMasonryDataShow(builderData),
            ...(await handleButtonDataShow(builderData)),
            ...(await handleBlogDataShow(builderData)),
            ...(await handleProductDataShow(builderData)),
            ...(await handleFormDataShow(builderData)),
        }
    }
    const handleSingleElements = async (treeItem, parentInd, tab) => {
        const builderData = createBuilderData(componentsData, treeItem[0][0])
        return {
            idArray: treeItem,
            parentCls: "zg-grid-col zg-col-lg zg-col-sm zg-col-md",
            // TODO!!! check it
            // childWrapper: {display: "flex"},
            childWrapper: {},
            container: !initialLayout?.[6]?.[0]?.[tab - 1]?.[parentInd] ? "container" : "",
            parentAlignItems: initialLayout[4]?.[0]?.[tab - 1]?.[parentInd] ?? "flex-start",
            showOneComponent: await showOneComponentToggle(builderData, tab),
            rowStyles: builderRowStylesHandler({
                index: parentInd,
                tab,
                dbName,
                renderRowBackground: initialLayout[1],
                renderRowBgImage: initialLayout[2],
                renderRowHeight: initialLayout[3],
                renderRowAlignItems: initialLayout[5],
                renderParallax: initialLayout[8],
            }),
        }
    }

    async function handleNestedElements(treeItem, parentInd, tab) {
        const idArray = await Promise.all(treeItem.map(async (element, index) => {
            if (element?.length === 1) {
                const builderData = createBuilderData(componentsData, element[0]);
                return {
                    childIdArray: element,
                    showOneComponent: await showOneComponentToggle(builderData, tab),
                    childCls: initialLayout[0]?.[0]?.[tab - 1]?.[parentInd]?.[index],
                    secondChildCls: "builder_component",
                    columnStyles: {},
                };
            }

            const childIdArray = await Promise.all(element.map(async (elInEl) => {
                const builderData = createBuilderData(componentsData, elInEl);
                return await showOneComponentToggle(builderData, tab);
            }));

            return {
                childIdArray,
                childCls: initialLayout[0]?.[0]?.[tab - 1]?.[parentInd]?.[index],
                secondChildCls: "",
                columnStyles: builderColumnStylesHandler({
                    index: parentInd,
                    indexCol: index,
                    tab,
                    dbName,
                    renderColumn: initialLayout?.[7]?.[0]?.[tab - 1]?.[parentInd]?.[index],
                }),
            };
        }));

        return {
            idArray,
            parentCls: "zg-row",
            childWrapper: {display: "flex", flexWrap: "wrap"},
            container: !initialLayout?.[6]?.[0]?.[tab - 1]?.[parentInd] ? "container" : "",
            parentAlignItems: initialLayout[4]?.[0]?.[tab - 1]?.[parentInd] ?? "flex-start",
            rowStyles: builderRowStylesHandler({
                index: parentInd,
                tab,
                dbName,
                renderRowBackground: initialLayout[1],
                renderRowBgImage: initialLayout[2],
                renderRowHeight: initialLayout[3],
                renderRowHeightIsActive: initialLayout[9],
                renderRowAlignItems: initialLayout[5],
                renderParallax: initialLayout[8],
            }),
        };
    }

    async function processTree(tree, tab) {
        if (!Array.isArray(tree)) return null;
        try {
            return await Promise.all(tree.map(async (item, ind) => {
                if (item?.length === 1 && item?.[0]?.length === 1) {
                    return handleSingleElements(item, ind, tab);
                } else {
                    return await handleNestedElements(item, ind, tab);
                }
            }));
        } catch (error) {
            console.error("Error in processTree:", error);
            throw error;
        }
    }

    return {
        1: tree && await processTree(tree[1], 1),
        2: tree && await processTree(tree[2], 2),
        3: tree && await processTree(tree[3], 3),
        4: tree && await processTree(tree[4], 4),
    };
}

const Get_Data_For_Builder = (pageId, locale, models) => {
    return new Promise(async (resolve, reject) => {
        models
            .page_builder
            .aggregate([
                {
                    $match: {
                        page_id: +pageId,
                        selectedLocale: locale
                    },
                }
            ])
            .then((res) => {
                resolve(JSON.parse(JSON.stringify(res)))
            })
            .catch((err) => reject(err))
    })
}

const Get_All_Data_With_Locales = (pageId, models) => {
    return new Promise(async (resolve, reject) => {
        models
            .page_builder
            .aggregate([
                {
                    $match: {
                        page_id: +pageId,
                    },
                }
            ])
            .then(res => res.map(el => {
                return {
                    page_id: el.page_id,
                    selectedLocale: el.selectedLocale
                }
            }))
            .then((res) => {
                resolve(JSON.parse(JSON.stringify(res)))
            })
            .catch((err) => reject(err))
    })
}

module.exports = {Get_Data, Save_Builder_data, Get_Data_For_Builder, Get_All_Data_With_Locales}

